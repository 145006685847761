var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-my-report"},[_c('base-header',{staticClass:"pb-1",attrs:{"type":""}},[_c('bread-bag')],1),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row card-wrapper"},[_c('div',{staticClass:"col-12"},[_c('card',{attrs:{"body-classes":"px-4"}},[_c('div',{staticClass:"px-0 mb-4"},[_c('v-filters',{attrs:{"search":false,"start-open":true,"enable-filters":true},on:{"reset-search":_vm.resetSearch,"filter-view-change":function($event){return _vm.$handleFilterView($event)}}},[_c('div',{staticClass:"col-12 col-md-3"},[_c('date-range-picker',{ref:"picker",staticClass:"range-picker",attrs:{"opens":"right","timePicker":false,"showWeekNumbers":true,"autoApply":true,"linkedCalendars":true,"locale-data":_vm.getDateRangePickerLocale(),"ranges":_vm.getDateRangePickerRanges()},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('manual-range',{attrs:{"date-range-manual":_vm.dateRangeManual,"date-range-error":_vm.dateRangeError},on:{"exec":function($event){return _vm.execManualRange(
                                                    'apiSearchFilter',
                                                    'dateRange'
                                                )}}})]},proxy:true},{key:"input",fn:function(picker){return [(picker.startDate)?[_vm._v(" "+_vm._s(_vm.$d( picker.startDate, "short" ))+" - "+_vm._s(_vm.$d(picker.endDate, "short"))+" ")]:_vm._e()]}}]),model:{value:(_vm.apiSearchFilter.dateRange),callback:function ($$v) {_vm.$set(_vm.apiSearchFilter, "dateRange", $$v)},expression:"apiSearchFilter.dateRange"}})],1),(_vm.stats.length > 0)?_c('div',{staticClass:"col-12 col-md-3"},[_c('div',{staticStyle:{"margin-top":"14px"}},[_c('a',{attrs:{"href":_vm.currentExportUrl}},[_c('i',{staticClass:"fa fa-file-csv"}),_vm._v(" "+_vm._s(_vm.$t( "personal_multiview.export_as_csv" )))])])]):_vm._e(),(
                                    _vm.apiSearchFilter.dateRange.startDate ===
                                        null
                                )?_c('div',{staticClass:"col-12 mt-3"},[_c('div',{staticClass:"text-bold text-warning"},[_vm._v(" "+_vm._s(_vm.$t( "personal_multiview.warn_select_dates" ))+" ")])]):_vm._e()])],1),_c('hr'),(_vm.loading)?_c('v-loader',{attrs:{"loading":_vm.loading}}):('user_id' in _vm.stats)?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('h2',{staticClass:"mb-5"},[_vm._v(" "+_vm._s(_vm.$t("my_report.time_summary"))+" ")]),_c('hr'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('dl',[_c('dt',[_vm._v(" "+_vm._s(_vm.$t( "personal_multiview.time_total" ))+" ")]),_c('dd',[_vm._v(" "+_vm._s(_vm._f("toTime")(_vm.stats.time_total))+" ")])])]),_c('div',{staticClass:"col"},[_c('dl',[_c('dt',[_vm._v(" "+_vm._s(_vm.$t( "personal_multiview.time_goal" ))+" ")]),_c('dd',[(
                                                        parseInt(
                                                            _vm.stats.daily_goal
                                                        ) > 0
                                                    )?[_vm._v(" "+_vm._s(_vm._f("toTime")(_vm.stats.time_goal))+" ")]:[_vm._v(_vm._s(_vm.$t( "users.paid_per_hour" )))]],2)])]),_c('div',{staticClass:"col"},[_c('dl',[_c('dt',[_vm._v(" "+_vm._s(_vm.$t( "personal_multiview.time_balance" ))+" ")]),_c('dd',[(
                                                        parseInt(
                                                            _vm.stats.daily_goal
                                                        ) > 0
                                                    )?[_c('span',{class:parseInt(
                                                                _vm.stats.time_balance
                                                            ) < 0
                                                                ? 'text-danger'
                                                                : ''},[(
                                                                parseInt(
                                                                    _vm.stats.time_balance
                                                                ) < 0
                                                            )?[_vm._v("-")]:_vm._e(),_vm._v(" "+_vm._s(_vm._f("toTime")(_vm.stats.time_balance))+" ")],2)]:[_vm._v(_vm._s(_vm.$t( "users.paid_per_hour" )))]],2)])])]),_c('hr'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('dl',[_c('dt',[_vm._v(" "+_vm._s(_vm.$t( "personal_multiview.customer_related_time" ))+" ")]),_c('dd',[_vm._v(" "+_vm._s(_vm._f("toTime")(_vm.stats.time_customers))+" ")]),_c('dt',[_vm._v(" "+_vm._s(_vm.$t( "personal_multiview.internal_time" ))+" ")]),_c('dd',[_vm._v(" "+_vm._s(_vm._f("toTime")(_vm.stats.time_internal))+" ")])])]),_c('div',{staticClass:"col-6"},[_c('dl',_vm._l((Object.keys(
                                                    _vm.absence
                                                )),function(absence_key){return _c('div',{key:absence_key},[_c('dt',[_vm._v(" "+_vm._s(_vm.absence[absence_key])+" ")]),_c('dd',[_vm._v(" "+_vm._s(_vm._f("toTime")(_vm.stats[absence_key]))+" ")])])}),0)])])]),_c('div',{staticClass:"col-6"},[_c('h2',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(_vm.$t( "my_report.detailed_registration_and_missing_days" ))+" ")]),(parseInt(_vm.stats.daily_goal) > 0)?_c('p',[_vm._v(" "+_vm._s(_vm.$t("my_report.my_daily_goal"))+": "+_vm._s(_vm._f("toTime")(_vm.stats.daily_goal))+" ")]):_vm._e(),_c('v-table',{staticClass:"table table-sm table-striped table-bordered missing-days-table"},[_c('v-thead',[_c('v-tr',[_c('v-th',[_vm._v(_vm._s(_vm.$t( "missing_days_modal.date" )))]),_c('v-th',[_vm._v(_vm._s(_vm.$t( "missing_days_modal.day_of_week" )))]),_c('v-th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$t( "missing_days_modal.registered_time" )))]),_c('v-th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$t( "personal_multiview.time_balance" )))])],1)],1),_c('v-tbody',_vm._l((_vm.noregs),function(nr,index){return _c('v-tr',{key:index,class:("day-type-" + (nr.day_type))},[_c('v-td',[_vm._v(_vm._s(_vm.$d(new Date(index), "short")))]),_c('v-td',[_vm._v(_vm._s(_vm.$d( new Date(index), "ldow" ))+" ")]),_c('v-td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("toTime")(nr.time_reg)))]),_c('v-td',{staticClass:"text-right",class:{
                                                    'bg-danger text-white':
                                                        !nr.is_goal &&
                                                        nr.day_type ===
                                                            'business'
                                                }},[(
                                                        nr.day_type ===
                                                            'business'
                                                    )?[(
                                                            nr.time_reg -
                                                                nr.goal <
                                                                0
                                                        )?_c('span',[_vm._v("-")]):_vm._e(),_vm._v(_vm._s(_vm._f("toTime")((nr.time_reg - nr.goal))))]:[_vm._v("("+_vm._s(_vm.$t( "missing_days_modal.free_day" ))+")")]],2)],1)}),1)],1)],1)]),_c('hr')]):_c('div',{staticClass:"px-4"},[_c('div',{staticClass:"alert alert-warning"},[_vm._v(" "+_vm._s(_vm.$t("personal_multiview.no_results_found"))+" ")])])],1)],1)])]),_c('portal',{attrs:{"to":"modals"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }