var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{staticClass:"asset-modal",attrs:{"show":true,"loading":_vm.loading},on:{"close":function($event){return _vm.$emit('close')}}},[_c('template',{slot:"header"},[_vm._v(_vm._s(_vm.context.stat.name))]),_c('template',{slot:"default"},[_c('div',{staticClass:"text-bold text-center mb-3",class:parseInt(_vm.context.stat.time_balance) < 0
                    ? 'text-danger'
                    : 'text-success'},[_vm._v(" "+_vm._s(_vm.$t("missing_days_modal.balance"))+" "),_c('i',{staticClass:"fa",class:parseInt(_vm.context.stat.time_balance) < 0
                        ? 'fa-minus'
                        : 'fa-plus'}),_vm._v(" "+_vm._s(_vm._f("toTime")(_vm.context.stat.time_balance))+" ")]),_c('v-table',{staticClass:"table table-sm table-striped missing-days-table"},[_c('v-thead',[_c('v-tr',[_c('v-th',[_vm._v(_vm._s(_vm.$t("missing_days_modal.date")))]),_c('v-th',[_vm._v(_vm._s(_vm.$t("missing_days_modal.day_of_week")))]),_c('v-th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$t("missing_days_modal.registered_time")))]),_c('v-th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$t("missing_days_modal.missing_time")))])],1)],1),_c('v-tbody',_vm._l((_vm.context.noregs),function(nr,index){return _c('v-tr',{key:index,class:("day-type-" + (nr.day_type))},[_c('v-td',[_vm._v(_vm._s(_vm.$d(new Date(index), "short")))]),_c('v-td',[_vm._v(_vm._s(_vm.$d(new Date(index), "ldow"))+" ")]),_c('v-td',{staticClass:"text-right",class:{
                            'bg-danger text-white':
                                !nr.is_goal && nr.day_type === 'business',
                        }},[_vm._v(_vm._s(_vm._f("toTime")(nr.time_reg)))]),_c('v-td',{staticClass:"text-right"},[(nr.day_type === 'business')?[_vm._v(_vm._s(_vm._f("toTime")((nr.time_reg - nr.goal))))]:[_vm._v("("+_vm._s(_vm.$t("missing_days_modal.free_day"))+")")]],2)],1)}),1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }