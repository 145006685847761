<script>
export default {
    name: "VMissingDays",
    props: {
        context: {},
    },

    created() {},
};
</script>

<template>
    <modal
        :show="true"
        :loading="loading"
        @close="$emit('close')"
        class="asset-modal"

    >
        <template slot="header">{{ context.stat.name }}</template>
        <template slot="default">
            <div
                class="text-bold text-center mb-3"
                :class="
                    parseInt(context.stat.time_balance) < 0
                        ? 'text-danger'
                        : 'text-success'
                "
            >
                {{ $t("missing_days_modal.balance") }}
                <i
                    class="fa"
                    :class="
                        parseInt(context.stat.time_balance) < 0
                            ? 'fa-minus'
                            : 'fa-plus'
                    "
                ></i>
                {{ context.stat.time_balance | toTime }}
            </div>
            <v-table class="table table-sm table-striped missing-days-table">
                <v-thead>
                    <v-tr>
                        <v-th>{{ $t("missing_days_modal.date") }}</v-th>
                        <v-th>{{ $t("missing_days_modal.day_of_week") }}</v-th>
                        <v-th class="text-right">{{
                            $t("missing_days_modal.registered_time")
                        }}</v-th>
                        <v-th class="text-right">{{
                            $t("missing_days_modal.missing_time")
                        }}</v-th>
                    </v-tr>
                </v-thead>

                <v-tbody>
                    <v-tr
                        v-for="(nr, index) in context.noregs"
                        :key="index"
                        :class="`day-type-${nr.day_type}`"
                    >
                        <v-td>{{ $d(new Date(index), "short") }}</v-td>
                        <v-td>{{ $d(new Date(index), "ldow") }} </v-td>
                        <v-td
                            class="text-right"
                            :class="{
                                'bg-danger text-white':
                                    !nr.is_goal && nr.day_type === 'business',
                            }"
                            >{{ nr.time_reg | toTime }}</v-td
                        >
                        <v-td class="text-right">
                            <template v-if="nr.day_type === 'business'">{{
                                (nr.time_reg - nr.goal) | toTime
                            }}</template>
                            <template v-else
                                >({{
                                    $t("missing_days_modal.free_day")
                                }})</template
                            ></v-td
                        >
                    </v-tr>
                </v-tbody>
            </v-table>
        </template>
    </modal>
</template>


<style lang="scss">
.missing-days-table {
    tr.day-type-free {
        background: $primary !important;

        td {
            color: white !important;
        }
    }
}
</style>
