<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import DateRangeMixin from "@/mixins/DateRangeMixin";
import DepartmentService from "@/services/DepartmentService";
import VFilters from "@/components/Search/VFilters";
import MessagesMixin from "@/mixins/Messages";
import TaskService from "@/services/TaskService";
import VMissingDays from "@/views/Reports/components/VMissingDays";
import InnerDashCard from "@/components/Cards/InnerDashCard";

export default {
    name: "VPersonalMultiview",
    mixins: [DateRangeMixin, MessagesMixin],
    components: {
        VFilters,
        DateRangePicker,
        VMissingDays,
        InnerDashCard
    },
    data() {
        return {
            loading: true,
            stats: [],
            absence: {},
            showModal: false,
            context: null,
            goals: [],
            noregs: [],
            empty: [],
            deps: []
        };
    },
    computed: {
        currentExportUrl: function() {
            let url = new URL(
                location.protocol +
                    "//" +
                    location.host +
                    "/v1/tasks/personal-multiview-report"
            );
            url.searchParams.append("csv", 1);
            let params = this.$buildParams();

            for (let param in params) {
                if (param == "dateRange") {
                    url.searchParams.append(
                        param,
                        JSON.stringify(params[param])
                    );
                } else {
                    if (Array.isArray(params[param])) {
                        params[param].forEach(function(value) {
                            url.searchParams.append(param + "[]", value);
                        });
                    } else {
                        url.searchParams.append(param, params[param]);
                    }
                }
            }

            return url.href;
        }
    },
    async created() {
        this.resetSearch();
        this.$apiEnableWatcher();
        await this.fetchDepartments(true);
        await this.fetchData();
    },
    methods: {
        async fetchData() {
            if (this.apiSearchFilter.dateRange.startDate === null) {
                this.loading = false;
                return;
            }

            try {
                this.loading = true;
                let params = this.$buildParams();
                const { data } = await TaskService.GET_myReport(params);
                this.absence = data.data.absence;
                this.stats = data.data.stats;
                this.noregs = data.data.noregs;
                this.goals = data.data.goals;
                this.empty = data.data.users_empty;
            } catch (e) {
                console.log("e fetchData", e);
            }

            this.loading = false;
        },
        async fetchDepartments(loading = false) {
            try {
                this.loading = true;
                const r = await DepartmentService.dropdown();
                this.deps = r.data.data;
            } catch (e) {
                console.log("fetchDepartments e", e);
            }

            this.loading = loading;
        },
        resetSearch() {
            this.apiSearchFilter = {
                dateRange: {
                    startDate: null,
                    endDate: null
                }
            };
        },
        /**
         * Close details modal
         */
        closeDetails() {
            this.context = null;
            this.showModal = false;
        },
        /**
         * Open details modal with not clsoed days
         */
        showDetails(stat, noregs) {
            this.context = { stat, noregs };
            this.showModal = true;
        }
    }
};
</script>

<template>
    <div class="v-my-report">
        <base-header class="pb-1" type=""><bread-bag></bread-bag></base-header>
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <card body-classes="px-4">
                        <!-- V-FILTERS -->
                        <div class="px-0 mb-4">
                            <v-filters
                                :search="false"
                                :start-open="true"
                                :enable-filters="true"
                                @reset-search="resetSearch"
                                @filter-view-change="$handleFilterView($event)"
                            >
                                <!-- Default slot starts here -> filters -->
                                <div class="col-12 col-md-3">
                                    <date-range-picker
                                        class="range-picker"
                                        ref="picker"
                                        opens="right"
                                        :timePicker="false"
                                        :showWeekNumbers="true"
                                        :autoApply="true"
                                        v-model="apiSearchFilter.dateRange"
                                        :linkedCalendars="true"
                                        :locale-data="
                                            getDateRangePickerLocale()
                                        "
                                        :ranges="getDateRangePickerRanges()"
                                    >
                                        <template #header>
                                            <manual-range
                                                :date-range-manual="
                                                    dateRangeManual
                                                "
                                                :date-range-error="
                                                    dateRangeError
                                                "
                                                @exec="
                                                    execManualRange(
                                                        'apiSearchFilter',
                                                        'dateRange'
                                                    )
                                                "
                                            />
                                        </template>
                                        <template
                                            v-slot:input="picker"
                                            style="min-width: 350px"
                                        >
                                            <template v-if="picker.startDate">
                                                {{
                                                    $d(
                                                        picker.startDate,
                                                        "short"
                                                    )
                                                }}
                                                -
                                                {{
                                                    $d(picker.endDate, "short")
                                                }}
                                            </template>
                                        </template>
                                    </date-range-picker>
                                </div>
                                <div
                                    class="col-12 col-md-3"
                                    v-if="stats.length > 0"
                                >
                                    <div style="margin-top: 14px">
                                        <a :href="currentExportUrl" class=""
                                            ><i class="fa fa-file-csv"></i>
                                            {{
                                                $t(
                                                    "personal_multiview.export_as_csv"
                                                )
                                            }}</a
                                        >
                                    </div>
                                </div>
                                <div
                                    class="col-12 mt-3"
                                    v-if="
                                        apiSearchFilter.dateRange.startDate ===
                                            null
                                    "
                                >
                                    <div class="text-bold text-warning">
                                        {{
                                            $t(
                                                "personal_multiview.warn_select_dates"
                                            )
                                        }}
                                    </div>
                                </div>
                            </v-filters>
                        </div>

                        <!-- BODY -->
                        <hr />
                        <v-loader :loading="loading" v-if="loading" />
                        <div v-else-if="'user_id' in stats">
                            <div class="row">
                                <div class="col-6">
                                    <h2 class="mb-5">
                                        {{ $t("my_report.time_summary") }}
                                    </h2>
                                    <hr />
                                    <div class="row">
                                        <div class="col">
                                            <dl>
                                                <dt>
                                                    {{
                                                        $t(
                                                            "personal_multiview.time_total"
                                                        )
                                                    }}
                                                </dt>
                                                <dd>
                                                    {{
                                                        stats.time_total
                                                            | toTime
                                                    }}
                                                </dd>
                                            </dl>
                                        </div>
                                        <div class="col">
                                            <dl>
                                                <dt>
                                                    {{
                                                        $t(
                                                            "personal_multiview.time_goal"
                                                        )
                                                    }}
                                                </dt>
                                                <dd>
                                                    <template
                                                        v-if="
                                                            parseInt(
                                                                stats.daily_goal
                                                            ) > 0
                                                        "
                                                    >
                                                        {{
                                                            stats.time_goal
                                                                | toTime
                                                        }}
                                                    </template>
                                                    <template v-else>{{
                                                        $t(
                                                            "users.paid_per_hour"
                                                        )
                                                    }}</template>
                                                </dd>
                                            </dl>
                                        </div>
                                        <div class="col">
                                            <dl>
                                                <dt>
                                                    {{
                                                        $t(
                                                            "personal_multiview.time_balance"
                                                        )
                                                    }}
                                                </dt>
                                                <dd>
                                                    <template
                                                        v-if="
                                                            parseInt(
                                                                stats.daily_goal
                                                            ) > 0
                                                        "
                                                    >
                                                        <span
                                                            :class="
                                                                parseInt(
                                                                    stats.time_balance
                                                                ) < 0
                                                                    ? 'text-danger'
                                                                    : ''
                                                            "
                                                        >
                                                            <template
                                                                v-if="
                                                                    parseInt(
                                                                        stats.time_balance
                                                                    ) < 0
                                                                "
                                                                >-</template
                                                            >
                                                            {{
                                                                stats.time_balance
                                                                    | toTime
                                                            }}
                                                        </span>
                                                    </template>
                                                    <template v-else>{{
                                                        $t(
                                                            "users.paid_per_hour"
                                                        )
                                                    }}</template>
                                                </dd>
                                            </dl>
                                        </div>
                                    </div>

                                    <hr />
                                    <div class="row">
                                        <div class="col-6">
                                            <dl>
                                                <dt>
                                                    {{
                                                        $t(
                                                            "personal_multiview.customer_related_time"
                                                        )
                                                    }}
                                                </dt>
                                                <dd>
                                                    {{
                                                        stats.time_customers
                                                            | toTime
                                                    }}
                                                </dd>

                                                <dt>
                                                    {{
                                                        $t(
                                                            "personal_multiview.internal_time"
                                                        )
                                                    }}
                                                </dt>
                                                <dd>
                                                    {{
                                                        stats.time_internal
                                                            | toTime
                                                    }}
                                                </dd>
                                            </dl>
                                        </div>
                                        <div class="col-6">
                                            <dl>
                                                <div
                                                    v-for="absence_key in Object.keys(
                                                        absence
                                                    )"
                                                    :key="absence_key"
                                                >
                                                    <dt>
                                                        {{
                                                            absence[absence_key]
                                                        }}
                                                    </dt>
                                                    <dd>
                                                        {{
                                                            stats[absence_key]
                                                                | toTime
                                                        }}
                                                    </dd>
                                                </div>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                                <!-- right pane -->
                                <div class="col-6">
                                    <h2 class="mb-3">
                                        {{
                                            $t(
                                                "my_report.detailed_registration_and_missing_days"
                                            )
                                        }}
                                    </h2>

                                    <p v-if="parseInt(stats.daily_goal) > 0">
                                        {{ $t("my_report.my_daily_goal") }}:
                                        {{ stats.daily_goal | toTime }}
                                    </p>

                                    <v-table
                                        class="table table-sm table-striped table-bordered missing-days-table"
                                    >
                                        <v-thead>
                                            <v-tr>
                                                <v-th>{{
                                                    $t(
                                                        "missing_days_modal.date"
                                                    )
                                                }}</v-th>
                                                <v-th>{{
                                                    $t(
                                                        "missing_days_modal.day_of_week"
                                                    )
                                                }}</v-th>
                                                <v-th class="text-right">{{
                                                    $t(
                                                        "missing_days_modal.registered_time"
                                                    )
                                                }}</v-th>
                                                <v-th class="text-right">{{
                                                    $t(
                                                        "personal_multiview.time_balance"
                                                    )
                                                }}</v-th>
                                            </v-tr>
                                        </v-thead>

                                        <v-tbody>
                                            <v-tr
                                                v-for="(nr, index) in noregs"
                                                :key="index"
                                                :class="
                                                    `day-type-${nr.day_type}`
                                                "
                                            >
                                                <v-td>{{
                                                    $d(new Date(index), "short")
                                                }}</v-td>
                                                <v-td
                                                    >{{
                                                        $d(
                                                            new Date(index),
                                                            "ldow"
                                                        )
                                                    }}
                                                </v-td>
                                                <v-td class="text-right">{{
                                                    nr.time_reg | toTime
                                                }}</v-td>
                                                <v-td
                                                    class="text-right"
                                                    :class="{
                                                        'bg-danger text-white':
                                                            !nr.is_goal &&
                                                            nr.day_type ===
                                                                'business'
                                                    }"
                                                >
                                                    <template
                                                        v-if="
                                                            nr.day_type ===
                                                                'business'
                                                        "
                                                    >
                                                        <span
                                                            v-if="
                                                                nr.time_reg -
                                                                    nr.goal <
                                                                    0
                                                            "
                                                            >-</span
                                                        >{{
                                                            (nr.time_reg -
                                                                nr.goal)
                                                                | toTime
                                                        }}</template
                                                    >
                                                    <template v-else
                                                        >({{
                                                            $t(
                                                                "missing_days_modal.free_day"
                                                            )
                                                        }})</template
                                                    ></v-td
                                                >
                                            </v-tr>
                                        </v-tbody>
                                    </v-table>
                                </div>
                            </div>
                            <hr />
                        </div>
                        <div class="px-4" v-else>
                            <div class="alert alert-warning">
                                {{ $t("personal_multiview.no_results_found") }}
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
        <portal to="modals"> </portal>
    </div>
</template>

<style lang="scss">
.v-my-report {
    dl {
        dt {
            font-weight: normal;
        }

        dd {
            font-size: 25px;
            font-weight: 600;
            margin-bottom: 20px;
        }
    }
}
</style>
